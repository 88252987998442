@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sign-in-button {
  @apply bg-emerald-600 hover:bg-green-800 w-full flex justify-center items-center text-white rounded-md disabled:bg-gray-400 h-[52px] shadow
}

.sign-out-button {
  @apply bg-rose-500 hover:bg-rose-800 text-white rounded-md disabled:bg-gray-400 p-2 text-xs shadow
}

.manage-profile-button {
  @apply bg-emerald-600 hover:bg-green-800 text-white rounded-md disabled:bg-gray-400 p-2 text-xs shadow
}

.manage-billing-button {
  @apply bg-purple-600 hover:bg-purple-800 text-white rounded-md disabled:bg-gray-400 p-2 text-xs shadow
}

.back-button {
  @apply bg-neutral-500 hover:bg-neutral-600 text-white rounded-md p-2 text-xs shadow
}

.back-button-white {
  @apply bg-white hover:bg-neutral-200 text-neutral-700 rounded-md p-1 border-2 shadow
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.login-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  animation: gradient 10s ease infinite;
  background: linear-gradient(-45deg, #ee7752, rgb(25 212 91), #23a6d5, rgb(226 78 151));
  background-size: 400% 400%;
}


.login-title {
  @apply text-slate-600 mb-6 mt-0 text-2xl text-center
}

.login-content {
  @apply flex flex-col items-center p-2
}

.grecaptcha-badge {
  visibility: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input[type='number'] {
  padding: 0;
  line-height: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  color: transparent !important;
}


.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #78716c;
}

.separator:not(:empty)::before {
  margin-right: 1em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}

::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #427dab;
  border-radius: 20px;
}

.react-tel-input .form-control{
  width: 220px !important;
}